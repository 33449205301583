import * as React from "react"
import { Link, graphql } from "gatsby"
import { convertToCzDate } from "../components/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog-post.scss"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const { previous, next } = data;

  var siteImage = post.frontmatter.thumbnail || '/logo_inverse.png';
  const date = convertToCzDate(post.frontmatter.date);
  console.log(siteImage);
  var splitPath = siteImage.split("static/");
  if (splitPath.length > 1) {
    siteImage = splitPath[0] + splitPath[1];
  }
  console.log(siteImage);
  return (
    <Layout location={location}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']}
        image={siteImage}
     />
       <section id="blog-post" className="page-section">
          <div className="container">
            <img src={siteImage} style={{display: "none"}}></img>
            <article
              className="blog-post-article"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h1 itemProp="headline" className="title">{post.frontmatter.title}</h1>
                <p className="date">{date}</p>
              </header>
              <section
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <hr />
            </article>
            <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
              <li>
                {next && (
                  <Link className="link-nav" to={next.fields.slug} rel="next">
                    <i className="fa fa-arrow-left" aria-hidden="true"></i> {next.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                <Link className="link-nav" to="/news" rel="prev">
                Všechny novinky
                </Link>
              </li>
              <li>
                {previous && (
                  <Link className="link-nav" to={previous.fields.slug} rel="prev">
                    {previous.frontmatter.title} <i className="fa fa-arrow-right" aria-hidden="true"></i>
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
